// /* globals LCP */
// @todo enable next eslint rules
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-new */
import React, { Component } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
// import {
//   observeElement,
//   getWidget,
//   isBrowser,
// } from '../../utils/helpers';
// import Spinner from '../Spinner';
import VerticalTextBlock from '../VerticalTextBlock';

import './index.scss';


const settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        dots: true,
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        slidesToShow: 1,
      },
    },
  ],
};

/**
 * @todo split `LastCompletedProjects` component
 * React best practices recommends to not combine business logic and UI.
 * Business logic is how component should fetch `LCP` widget.
 * UI is how component should looks like.
 */
export default class LastCompletedProjects extends Component {
  state = {
    lcpLoaded: true,
    items: [
      {
        orderId: '69514639',
        topicTitle: 'Acting as a management consultant for an Australian hotel',
        paperType: 'Essay',
        discipline: 'Business Studies',
        academicLevel: 'Undergraduate (yrs. 3-4)',
        delivered: 'Today',
        pages: 5,
        grade: 'A',
      },
      {
        orderId: '69476328',
        topicTitle: 'The Impact Of Social Media On Teens Behavior',
        paperType: 'Essay',
        discipline: 'Communications ',
        academicLevel: 'High school',
        delivered: 'Today',
        pages: 3,
        grade: 'A+',
      },
      {
        orderId: '69237659',
        topicTitle: 'The relevance of cultural diversity in the USA',
        paperType: 'Research paper',
        discipline: 'Cultural and Ethnic Studies',
        academicLevel: 'Undergraduate (yrs. 1-2)',
        delivered: 'Yesterday',
        pages: 2,
        grade: 'B',
      },
      {
        orderId: '68035477',
        topicTitle: 'Government and Not-For-Profit Accounting Portfolio ',
        paperType: 'Case Study',
        discipline: 'Accounting ',
        delivered: 'Yesterday',
        academicLevel: 'Undergraduate (yrs. 3-4)',
        pages: 5,
        grade: 'A',
      },
      {
        orderId: '68440376',
        topicTitle: 'Arsenic found in rice using Microwave Digestion and Nitric Acid',
        paperType: 'Research paper',
        discipline: 'Biology',
        academicLevel: 'Graduate',
        delivered: 'Yesterday',
        pages: 12,
        grade: 'A',
      },
    ],
  };

  // componentDidMount() {
  //   if (isBrowser && this.lcpRef) {
  //     observeElement({
  //       element: '[data-lcp]',
  //       marginValue: 500,
  //       callback: () => {
  //         getWidget({
  //           widgetLink: '/assets/js/lcp.js',
  //           fn: () => {
  //             this.getLcp();
  //           },
  //         });
  //       },
  //     });
  //   }
  // }

  /**
   * @todo write `Calc` instance to `this`
   *
   * @todo defined all Component properties
   * `LastCompletedProjects.options` may not be defined.
   */
  // getLcp = () => {
  //   /**
  //    * @todo remove redundant constant
  //    * @see https://hacks.mozilla.org/2015/06/es6-in-depth-arrow-functions/
  //    */
  //   const _this = this;
  //
  //   new LCP({
  //     ID: '169',
  //     format: 'json',
  //     limit: 5,
  //     fields: [
  //       'orderId',
  //       'topicTitle',
  //       'delivered',
  //       'discipline',
  //       'paperType',
  //       'academicLevel',
  //       'pages',
  //       'writerCategory',
  //     ],
  //     callback() {
  //       _this.setState({
  //         lcpLoaded: true,
  //         items: this.options.json,
  //       });
  //     },
  //   });
  // };

  render() {
    const {
      lcpLoaded,
      items,
    } = this.state;

    const renderItems = items.map(item => (
      <div key={`${item.orderId}-${item.pages}`}>
        <div className="lcp-row">
          <div className="lcp-row__id" data-title="ID">
            {item.orderId}
          </div>
          <div className="lcp-row__topic" data-title="Topic / type">
            {item.topicTitle}
            <span>
              {item.paperType}
            </span>
          </div>
          <div className="lcp-row__discipline" data-title="Discipline / level">
            {item.discipline}
            <span>
              {item.academicLevel}
            </span>
          </div>
          <div className="lcp-row__pages" data-title="Pages">
            {item.pages}
          </div>
          <div className="lcp-row__delivered" data-title="Delivered">
            {item.delivered}
          </div>
          <div className="lcp-row__grade" data-title="Grade">
            <strong>
              {item.grade}
            </strong>
          </div>
        </div>
      </div>
    ));


    return (
      <section
        ref={(el) => {
          this.lcpRef = el;
        }}
        className="projects-section"
        data-lcp
      >
        <div className="projects-section__container container">
          <VerticalTextBlock
            sectionClassName="projects"
            text="Projects"
            dotsStyle="style-1"
          />

          <div className="projects-section__content">
            <h2 className="projects-section__heading">
              Last completed
              <br />
              {' '}
              projects
            </h2>
            <div
              className={classNames(
                'projects-section__slider',
                !lcpLoaded && 'projects-section__slider_loading',
                lcpLoaded && 'projects-section__slider_loaded',
              )}
            >
              <div className="lcp-head">
                <div className="lcp-head__id">
                  ID
                </div>
                <div className="lcp-head__topic">
                  Topic / type
                </div>
                <div className="lcp-head__discipline">
                  Discipline / level
                </div>
                <div className="lcp-head__pages">
                  Pages
                </div>
                <div className="lcp-head__delivered">
                  Delivered
                </div>
                <div className="lcp-head__grade">
                  Grade
                </div>
              </div>
              {lcpLoaded && (
                <Slider {...settings}>
                  {renderItems}
                </Slider>
              )}

            </div>

            {/* {!lcpLoaded && <Spinner />} */}
          </div>
        </div>
      </section>
    );
  }
}
