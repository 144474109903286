import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
/**
 * @todo simplify import statements
 * @example
 * // Bad
 * import A from 'A';
 * import AB from 'A/AB';
 * import AC from 'A/AC';
 *
 * // Good
 * import A, { AB, AC } from 'A';
 */
import BenefitsSection from '../../components/BenefitsSection';
import BenefitsHeading from '../../components/BenefitsSection/BenefitsHeading';
import BenefitsItem from '../../components/BenefitsSection/BenefitsItem';
import BenefitsItemHeading from '../../components/BenefitsSection/BenefitsItemHeading';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import Samples from '../../components/Samples';
import SamplesContent from '../../components/Samples/SamplesContent';
import SamplesHeading from '../../components/Samples/SamplesHeading';
import SamplesSlider from '../../components/Samples/SamplesSlider';
import SamplesItem from '../../components/Samples/SamplesItem';
import LastCompletedProjects from '../../components/LastCompletedProjects';
import StepsSection from '../../components/StepsSection';
import StepsContent from '../../components/StepsSection/StepsContent';
import StepsHeading from '../../components/StepsSection/StepsHeading';
import StepsItem from '../../components/StepsSection/StepsItem';
import VerticalTextBlock from '../../components/VerticalTextBlock';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';

import './index.scss';


const LegitWritingServicePage = () => {
  const resoursesUrl = '/img/icons/landing--legit-writing-service/';

  return (
    <Layout
      pageWrapperClassName="legit-writing-service-page"
      headerClassName="header_transparent-bg"
    >

      <Helmet
        title="Legitimate Essay Writer - Legit Essay Writing Service"
        meta={[
          {
            name: 'description',
            content: 'TakeAwayEssay.com 📝 is a Legitimate Essay Writing Service that guarantees the highest quality of work provided by its professional writers. Clients’ reviews confirm our satisfaction with the Legit Essay Writing Service and its 24/7 operating hours.',
          },

          {
            property: 'og:title',
            content: 'Legit Essay Writing Service to Help You Write Essays of the Highest Quality | TakeAwayEssay.com',
          },
          {
            property: 'og:description',
            content: 'Our legit essay writing service proposes the most advantageous conditions and benefits. Read this page and learn more about them. Place an immediate order right now and we guarantee the quick accomplishment of your assignment in strict accordance with your requirements.',
          },
          { property: 'og:site_name', content: 'Takeawayessay.com' },
          { property: 'og:type', content: 'website' },
          { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
        ]}
        link={[
          { rel: 'canonical', href: 'https://takeawayessay.com/legit-writing-service.html' },
        ]}
      />

      <FirstScreenSection
        btnClassName="awesome-btn_white"
      >
        <h2
          className="first-screen-section__title first-screen-section__title_single-line-h"
        >
          Get legitimate essays from
          <br className="first-screen-section__title__br" />
          {' '}
          a trusted US writing service
        </h2>
        <p className="first-screen-section__heading">
          We provide original samples that are legal
          <br className="first-screen-section__heading__br" />
          {' '}
          to be used in your paper
        </p>
      </FirstScreenSection>

      <BenefitsSection>
        <BenefitsHeading>
          What you get from
          <br className="benefits-section__heading__br" />
          {' '}
          using our legit essay
          <br className="benefits-section__heading__br" />
          {' '}
          writing service
        </BenefitsHeading>
        <BenefitsItem>
          <BenefitsItemHeading>
            <img
              data-observed-image
              data-src={`${resoursesUrl}unique.svg`}
              width={50}
              height={47}
              alt="unique"
            />
            <h3>
              Unique papers
            </h3>
          </BenefitsItemHeading>
          <p>
            We don’t sell ready-made essays, but compile papers only upon demand. Each essay meets
            your custom needs and requirements. Thus, you
            can cite it in your own work, if properly referenced.
          </p>
        </BenefitsItem>
        <BenefitsItem>
          <BenefitsItemHeading>
            <img
              data-observed-image
              data-src={`${resoursesUrl}research.svg`}
              width={54}
              height={47}
              alt="research"
            />
            <h3>
              Solid research base
            </h3>
          </BenefitsItemHeading>
          <p>
            You don’t need to waste long hours on looking for a necessary piece of information. Our
            experts will check your instructions and pick
            the most suitable sources for you to use in your writing.
          </p>
        </BenefitsItem>
        <BenefitsItem>
          <BenefitsItemHeading>
            <img
              data-observed-image
              data-src={`${resoursesUrl}guidelines.svg`}
              width={39}
              height={51}
              alt="guidelines"
            />
            <h3>
              Detailed guidelines
            </h3>
          </BenefitsItemHeading>
          <p>
            If you have any troubles with understanding your professor’s requirements, you may place
            an inquiry and our specialists will provide a
            precise outline and guidelines to inspire you and help you complete a task.
          </p>
        </BenefitsItem>
      </BenefitsSection>

      <Calculator />
      <Testimonials nameOfPageArray="legit_writing_service" />

      <Samples>
        <VerticalTextBlock
          sectionClassName="samples"
          dotsStyle="style-4"
          text="Examples"
        />

        <SamplesContent>
          <SamplesHeading>
            <h2>
              Examples of
              <br />
              {' '}
              our essays
            </h2>
            <p>
              To make sure our writers are experienced enough, you may check examples of their
              works. These samples were specially designed to
              represent writers’ skills and we do not store any similar papers for further use. So,
              there’s no chance you could get a pre-written
              essay instead of a custom one.
            </p>
          </SamplesHeading>
          <SamplesSlider>
            <SamplesItem
              typeOfPaper="Descriptive essay"
              topicTitle="How Dictionaries Are Created"
              academicLevel="Undergraduate"
              discipline="English 101"
              paperFormat="APA"
              sources="4"
              totalPages="3"
              pdfFileName="descriptive_essay_english101"
            />
            <SamplesItem
              typeOfPaper="Research paper"
              topicTitle="The Impact of the Digital Economy on Small Business"
              academicLevel="Master's"
              discipline="Business Studies"
              paperFormat="Harvard"
              sources="2"
              totalPages="4"
              pdfFileName="research_paper_business"
            />
          </SamplesSlider>
        </SamplesContent>
      </Samples>

      <LastCompletedProjects />

      <StepsSection>
        <VerticalTextBlock
          sectionClassName="steps"
          dotsStyle="style-4"
          text="Principles"
        />

        <StepsContent>
          <StepsHeading>
            <h2>
              How do you ensure I can legally use your service?
            </h2>
            <p>
              We understand how important it is to supply you with legitimate writing services.
              That’s why we follow a number of principles which
              provide timely, affordable, and legitimate services.
            </p>
            <p>
              These essential principles ensure you get a quality paper sample delivered within a
              specified deadline, and you can use it as a guide
              for your own writing or as one of your references.
            </p>
          </StepsHeading>
          <StepsItem>
            <p>
              We craft sample papers that can be used as detailed guides in your work
            </p>
          </StepsItem>
          <StepsItem>
            <p>
              We never reuse ready-made papers, but always deliver original content only
            </p>
          </StepsItem>
          <StepsItem>
            <p>
              We always check all sources to be sure they are credible
            </p>
          </StepsItem>
          <StepsItem>
            <p>
              We provide you with a custom tutorial to inspire your own writing
            </p>
          </StepsItem>
        </StepsContent>
      </StepsSection>

      <SeoTextSection>
        <SeoTextHeading>
          Legit essay writing service: legitimate essay writer
        </SeoTextHeading>

        <SeoTextContent>
          <div className="seo-text-section__text__css-half-column">
            <p>
              Oftentimes, students don’t reach their academic objectives because not all of their
              assignments were written perfectly. They have to face multiple types of tasks and each
              has a certain purpose. This variety confuses young minds. Besides, they may have
              problems with time management, writing skills, a specific discipline and so on.
              Consequently, they frequently ask “what writing service will do my essays for me?” We
              recommend using our legit essay writing service.
            </p>
            <p>
              Thousands of students from across the globe know our website as Takeawayessay.Com.{' '}
              <strong>
                We are chosen thanks to the highest quality of academic help we offer. Besides, we
                propose many other important conditions to ensure your success
              </strong>
              . Another huge reason why we’re amongst the best services of this kind is our legit
              status. There are multiple fakes on the web. Fortunately, our legitimate essay writing
              service is officially registered and has the necessary licenses. Moreover, we have 10+
              years of experience in business, which plainly prove that we can be trusted and are
              rated very high.
            </p>
            <h2 className="h3">Legitimate essay writing service with the necessary conditions</h2>
            <p>
              People eagerly buy legitimate essays on our college writing platform because of the
              high quality of every order we complete. Our experts are on the non-stop drive and
              constantly improve their skills and enlarge their knowledge. Thus, they easily write
              academic assignments of any type. They are very cautious and attentive to the demands.
              This allows for matching the top standards of any educational institution.
            </p>
            <p>
              If you hire a professional writer, he or she will offer different kinds of academic
              conditions. Our experts write, cite, proofread, research, create outlines and
              references, edit, etc. We offer more than 500 experts and so, you’ll definitely find
              the most suitable helper to meet all your requirements. Our experts successfully
              complete:
            </p>
            <ul className="dot-list">
              <li>Dissertation</li>
              <li>Term paper</li>
              <li>Case study</li>
              <li>Laboratory report</li>
              <li>Capstone project</li>
              <li>Book/movie/literature review</li>
              <li>Personal statement</li>
              <li>Any essay, etc.</li>
            </ul>
            <p>
              Takeawayessay.Com is a very responsible resource. Our writers are constantly monitored
              by skilled supervisors. Thus, we fully control the quality of every specialist. You’re
              welcome to visit our custom legitimate essay writing service whenever you want to
              request whatever you need. It’ll be accomplished precisely as you demand.
            </p>
            <h2 className="h3">Fair prices of a legit essay writing service</h2>
            <p>
              One of the major goals of our legit essay writing service is to make our assistance
              affordable. Compared to other similar websites, our customers save a lot. Our prices
              are relatively cheap. This means you’ll have to pay some sum, but it’s an advantageous
              proposal. Moreover, you’re the only person who decides how much money will be paid
              because every order is customized by you.
            </p>
            <p>
              To determine the sum, fill out the application order. Mention the next essentials:
            </p>
            <ul className="dot-list">
              <li>Assignment type and quality</li>
              <li>The length and urgency</li>
              <li>Kind of help</li>
              <li>Writing format</li>
              <li>Personal assistant, etc.</li>
            </ul>
            <p>
              Our online calculator provides instant results and if the price is acceptable,
              purchase your assignment. If you require a cheaper proposal, change your own settings.
              Thus, you won’t overpay and acquire high-quality help.
            </p>
            <h2 className="h3">Order the fastest legit essays</h2>
            <p>
              We offer legit essays on sale, which will be written precisely when you need it.
              Thanks to the advanced writing skills of our writers, over 97% of all orders were
              delivered before the deadlines our clients indicated. Our specialists continuously
              expand their possibilities. They find and practice different writing strategies and
              techniques. As they obtain experience, they know what method perfectly suits to finish
              the most urgent order on time.
            </p>
            <p>
              Our fast legit essay writing service is always ready to accept new challenges. We only
              ask you to provide a clear deadline and major instructions to understand how
              complicated your assignment is. An assigned writer will evaluate the odds to be sure
              the assignment is manageable. If it’s so, you’ll have it before the deadline you
              indicate expires.
            </p>
            <h2 className="h3">More dividends to enjoy</h2>
            <p>
              Mind that these were not all our advantages. Our customers will enjoy more. Here are
              other features:
            </p>
            <ul className="dot-list">
              <li>
                Absolute privacy. Takeawayessay.Com guarantees total protection of your private
                data. We apply the GDPR and our own privacy policy to store your data properly. Your
                confidential status is ensured with effective software. It easily deflects different
                kinds of cyber threats.
              </li>
              <li>
                100% originality. We also solve the issue of plagiarism because we implement
                specific software. It scans the entire Internet to define possible matches to your
                text. If there are any, we’ll rewrite them in an original way.
              </li>
              <li>
                24/7 benefits. In case you don’t understand some of our policies and restrictions,
                ask our technicians for help. Qualified experts will shed light on everything
                related to our website. You also enjoy free support assistance. The title and
                bibliography pages, all formats, and plagiarism checkups are offered for free.
              </li>
            </ul>
            <p>
              If you choose our legit essay writing service, you enjoy these dividends
              automatically. We compose academic papers of the highest quality precisely when you
              need them and don’t charge much. Don’t delay and place an order now to help us solve
              your complications in learning.
            </p>
          </div>
        </SeoTextContent>
      </SeoTextSection>

      <CTA>
        Need a better grade?
        <br />
        {' '}
        We&#39;ve got you covered.
      </CTA>

    </Layout>
  );
};

export default LegitWritingServicePage;
