import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


const StepsSection = ({ children }) => (
  <section className="steps-section">
    <div className="steps-section__container container">
      {children}
    </div>
  </section>
);

StepsSection.propTypes = {
  children: PropTypes.node,
};

StepsSection.defaultProps = {
  children: undefined,
};

export default StepsSection;
