import React from 'react';
import PropTypes from 'prop-types';


const StepsItem = ({ children }) => (
  <div className="steps-section__item">
    {children}
  </div>
);

StepsItem.propTypes = {
  children: PropTypes.node,
};

StepsItem.defaultProps = {
  children: undefined,
};

export default StepsItem;
