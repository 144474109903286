import React from 'react';
import PropTypes from 'prop-types';

import './SamplesItem.scss';


const Sample = ({
  typeOfPaper,
  topicTitle,
  academicLevel,
  discipline,
  paperFormat,
  sources,
  totalPages,
  pdfFileName,
}) => (
  <div className="sample-item">
    <h3 className="sample-item__name">
      {typeOfPaper}
    </h3>

    <div className="sample-item__card">
      <h4 className="sample-item__topic-title">
        {topicTitle}
      </h4>

      <div className="sample-item__table">
        <div className="sample-item__table__label">
          Academic level:
        </div>
        <div className="sample-item__table__text">
          {academicLevel}
        </div>
        <div className="sample-item__table__label">
          Discipline:
        </div>
        <div className="sample-item__table__text">
          {discipline}
        </div>
        <div className="sample-item__table__label">
          Paper format:
        </div>
        <div className="sample-item__table__text">
          {paperFormat}
        </div>
        <div className="sample-item__table__label">
          Sources:
        </div>
        <div className="sample-item__table__text">
          {sources}
        </div>
        <div className="sample-item__table__label">
          Total pages:
        </div>
        <div className="sample-item__table__text">
          {totalPages}
        </div>
      </div>
      <a
        href={`/samples/${pdfFileName}.pdf`}
        className="sample-item__pdf-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        View in PDF
      </a>
    </div>
  </div>
);

Sample.propTypes = {
  typeOfPaper: PropTypes.string,
  topicTitle: PropTypes.string,
  academicLevel: PropTypes.string,
  discipline: PropTypes.string,
  paperFormat: PropTypes.string,
  sources: PropTypes.string,
  totalPages: PropTypes.string,
  pdfFileName: PropTypes.string,
};

Sample.defaultProps = {
  typeOfPaper: undefined,
  topicTitle: undefined,
  academicLevel: undefined,
  discipline: undefined,
  paperFormat: undefined,
  sources: undefined,
  totalPages: undefined,
  pdfFileName: undefined,
};

export default Sample;
