import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';
import VideoBackground from './VideoBackground';
import './index.scss';


const FirstScreenBlock = ({
  children,
  containerClassName,
  btnClassName,
  isLogo,
  isVideo,
}) => (
  <section
    className={classNames(
      'first-screen-section',
      containerClassName || 'first-screen-section--main',
      btnClassName,
    )}
  >
    {isVideo && (
      <VideoBackground
        videoLink="/video/firstscreenvideo.mp4"
      />
    )}

    <div className="first-screen-section__container container">
      {isLogo && (
        <div className="first-screen-section__logo">
          <img
            data-observed-image
            data-src="/img/logo.svg"
            width="237"
            height="63"
            alt="Takeaway Essay"
          />
        </div>
      )}

      {children}

      <Link
        to="/dashboard/orders/create/"
        className={classNames(
          'awesome-btn',
          btnClassName,
        )}
        rel="nofollow"
      >
        <span>
          Order your paper
        </span>
      </Link>
    </div>
  </section>
);

FirstScreenBlock.propTypes = {
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  btnClassName: PropTypes.string,
  isLogo: PropTypes.bool,
  isVideo: PropTypes.bool,
};

FirstScreenBlock.defaultProps = {
  children: undefined,
  containerClassName: undefined,
  btnClassName: undefined,
  isLogo: false,
  isVideo: false,
};

export default FirstScreenBlock;
