import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Slider from 'react-slick';


const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  cssEase: 'ease',
  easing: 'swing',
  slidesToScroll: 1,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 1180,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        dots: true,
        arrows: false,
        slidesToShow: 1,
      },
    },
  ],
};

/**
 * @todo remove useless conditional rendering
 * @example
 * <SamplesSlider>
 *   <Slider>
 *     ...
 *   </Slider>
 * </SamplesSlider>
 *
 * <SamplesSlider>
 *   <div>
 *     ...
 *   </div>
 * <SamplesSlider>
 */
const SamplesSlider = ({ children, isOnlyOneItem }) => (
  <div className="samples-section__slider-wrap">
    <div className="samples-section__slider">
      {!isOnlyOneItem ? (
        <Slider {...settings}>
          {children}
        </Slider>
      ) : (
        <div className="samples-section__one-item">
          {children}
        </div>
      )}
    </div>
    <Link
      to="/our-samples.html"
      className="samples-section__btn btn_under-slider btn btn_normal btn_without-bg btn_blue btn_blue-color"
    >
      View all essays
    </Link>
  </div>
);


SamplesSlider.propTypes = {
  isOnlyOneItem: PropTypes.bool,
  children: PropTypes.node,
};

SamplesSlider.defaultProps = {
  isOnlyOneItem: false,
  children: undefined,
};

export default SamplesSlider;
