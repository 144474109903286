import React from 'react';
import PropTypes from 'prop-types';


const BenefitsItem = ({ children }) => (
  <div className="benefits-section__item">
    {children}
  </div>
);

BenefitsItem.propTypes = {
  children: PropTypes.node,
};

BenefitsItem.defaultProps = {
  children: undefined,
};

export default BenefitsItem;
