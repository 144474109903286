import React from 'react';
import PropTypes from 'prop-types';
import VerticalTextBlock from '../VerticalTextBlock';

import './index.scss';


const BenefitsSection = ({ children }) => (
  <section className="benefits-section">
    <div className="benefits-section__container container">
      <VerticalTextBlock
        sectionClassName="benefits"
        text="Benefits"
        dotsStyle="style-4"
      />

      <div className="benefits-section__content">
        <span className="benefits-section__circle benefits-section__circle_one" />
        <span className="benefits-section__circle benefits-section__circle_two" />
        <span className="benefits-section__circle benefits-section__circle_three" />
        <span className="benefits-section__circle benefits-section__circle_four" />

        <div className="benefits-section__wrap">
          {children}
        </div>
      </div>
    </div>
  </section>
);

BenefitsSection.propTypes = {
  children: PropTypes.node,
};

BenefitsSection.defaultProps = {
  children: undefined,
};

export default BenefitsSection;
