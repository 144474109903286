import React from 'react';
import PropTypes from 'prop-types';


const SamplesContent = ({ children }) => (
  <div className="samples-section__content">
    {children}
  </div>
);

SamplesContent.propTypes = {
  children: PropTypes.node,
};

SamplesContent.defaultProps = {
  children: undefined,
};

export default SamplesContent;
