import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import isBrowser from '../../utils/helpers/isBrowser';
import './VideoBackground.scss';


class VideoBackground extends Component {
  state = {
    isDesktop: false,
  };

  componentWillMount() {
    this.onResize();
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize, false);
  }

  componentWillUnmount() {
    window.addEventListener('resize', this.onResize, false);
  }

  onResize = debounce(100, () => {
    if (isBrowser) {
      if (document.documentElement.clientWidth >= 1024) {
        this.setState({
          isDesktop: true,
        });
      } else {
        this.setState({
          isDesktop: false,
        });
      }
    }
  });

  render() {
    const {
      isDesktop,
    } = this.state;
    const {
      videoLink,
    } = this.props;

    if (isDesktop) {
      return (
        <div className="vimeo-wrapper">
          <video
            title="Video"
            src={videoLink}
            autoPlay
            muted
            loop
          />
        </div>
      );
    }

    return null;
  }
}


VideoBackground.propTypes = {
  videoLink: PropTypes.string.isRequired,
};

export default VideoBackground;
