import React from 'react';
import PropTypes from 'prop-types';


/**
 * @todo remove redundant component
 * `BenefitsItemHeading` component always used as child of `BenefitsItem` component.
 * `BenefitsItemHeading` render result should be inserted in `BenefitsItem` render method.
 * `BenefitsItem` component should have string property `heading`.
 */
const BenefitsItemHeading = ({ children }) => (
  <div className="benefits-section__item__heading">
    {children}
  </div>
);

BenefitsItemHeading.propTypes = {
  children: PropTypes.node,
};

BenefitsItemHeading.defaultProps = {
  children: undefined,
};

export default BenefitsItemHeading;
