import React from 'react';
import PropTypes from 'prop-types';


const StepsContent = ({ children }) => (
  <div className="steps-section__content">
    {children}
  </div>
);

StepsContent.propTypes = {
  children: PropTypes.node,
};

StepsContent.defaultProps = {
  children: undefined,
};

export default StepsContent;
