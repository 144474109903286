import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const SamplesHeading = ({ children }) => (
  <div className="samples-section__heading">
    {children}
    <Link
      to="/our-samples.html"
      className="samples-section__btn btn btn_normal btn_without-bg btn_blue btn_blue-color"
    >
      View all essays
    </Link>
  </div>
);

SamplesHeading.propTypes = {
  children: PropTypes.node,
};

SamplesHeading.defaultProps = {
  children: undefined,
};

export default SamplesHeading;
