import React from 'react';
import PropTypes from 'prop-types';


/**
 *  @todo remove redundant component
 * `BenefitsHeading` component always used as child of `BenefitsBlock` component.
 * `BenefitsHeading` render result should be inserted in `BenefitsBlock` render method.
 * `BenefitsBlock` component should have string property `heading`.
 */
const BenefitsHeading = ({ children }) => (
  <h2 className="benefits-section__heading">
    {children}
  </h2>
);

BenefitsHeading.propTypes = {
  children: PropTypes.node,
};

BenefitsHeading.defaultProps = {
  children: undefined,
};

export default BenefitsHeading;
