import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';


const Samples = ({ children }) => (
  <section className="samples-section">
    <div className="samples-section__container container">
      {children}
    </div>
  </section>
);

Samples.propTypes = {
  children: PropTypes.node,
};

Samples.defaultProps = {
  children: undefined,
};

export default Samples;
